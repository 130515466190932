import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { Item, Widget } from '@revolut/ui-kit'
import { StageTitle } from '@src/pages/Forms/Candidate/StagesWidget/components/StageTitle'
import { StageIcon } from '@src/pages/Forms/Candidate/StagesWidget/components/StageIcon'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { PermissionTypes } from '@src/store/auth/types'
import { StageItems } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItems'

type StageProps = {
  candidate: CandidateInterface
  canViewEditOffer: boolean
  disableActions: boolean
  round: InterviewRoundInterface
  stage: InterviewStageWithoutRoundInterface
  stages: InterviewStageWithoutRoundInterface[]
  onClick?: StagesWidgetOnClickHandler
  onRefresh: () => void
}

export const Stage = ({
  candidate,
  canViewEditOffer,
  disableActions,
  round,
  stage,
  stages,
  onClick,
  onRefresh,
}: StageProps) => {
  const canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  const canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  const isArchived = !!round?.archived_reason
  const showActions = !disableActions && (canCancel || canAddFeedback) && !isArchived
  return (
    <Widget data-testid={`Stage ${stage.title}`}>
      <Item
        // htmlFor="" is to prevent click on item from triggering stage actions
        htmlFor=""
        use={showActions ? 'label' : undefined}
        useIcon={<StageIcon stage={stage} />}
        onClick={() => {
          if (showActions && onClick) {
            onClick(stage, undefined, stage.interview_type)
          }
        }}
      >
        <Item.Content>
          <Item.Title>
            <StageTitle round={round} stage={stage} />
          </Item.Title>
        </Item.Content>
        {showActions && (
          <Item.Side>
            <StageActions
              canAddFeedback={canAddFeedback}
              canCancel={canCancel}
              candidateId={candidate.id}
              canViewEditOffer={canViewEditOffer}
              currentStageId={round?.latest_interview_stage?.id}
              menuType="dropdown"
              roundId={round.id}
              stage={stage}
              stages={stages}
              onOpenSidebar={(stageData, mode, actionType) => {
                onClick?.(stageData, mode, undefined, actionType)
              }}
              onRefresh={onRefresh}
            />
          </Item.Side>
        )}
      </Item>
      <StageItems
        candidate={candidate}
        disable={!showActions}
        stage={stage}
        onClick={onClick}
      />
    </Widget>
  )
}
